import { Profile } from "../../core/models";

const profiles: Record<string, Profile> = {
  Md3mVl05V4: {
    id: "Md3mVl05V4",
    name: "Sally",
    profile_image: require("../../assets/headshots/h1.png"),
    location: "New York, NY",
    bio: "Hi, I'm Sally! I'm a software engineer at a large tech company. I love hiking, reading, and playing the piano.",
    interest: "downhill skiing, reading, playing the piano",
    hometown: "Chicago, IL",
    age: 25,
  },
  Md3mIad5V4: {
    id: "Md3mVl05V4",
    name: "Claire",
    profile_image: require("../../assets/headshots/h1.png"),
    location: "New York, NY",
    bio: "Hi, I'm Claire! I'm a software engineer at a large tech company. I love hiking, reading, and playing the piano.",
    interest: "downhill skiing, reading, playing the piano",
    hometown: "Chicago, IL",
    age: 25,
  },
  A34KV0yvRX8: {
    id: "A4KV0yvRX8",
    name: "Anna",
    profile_image: require("../../assets/headshots/h1.png"),
    location: "New York, NY",
    bio: "Hi, I'm Anna! I'm a software engineer at a large tech company. I love hiking, reading, and playing the piano.",
    interest: "downhill skiing, reading, playing the piano",
    hometown: "Chicago, IL",
    age: 25,
  },
  // Add more profiles as needed
};

export default profiles;
