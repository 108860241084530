import React, { FC } from "react";

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card: FC<CardProps> = ({ children, className }) => {
  return (
    <div
      className={`bg-white shadow  border  border-slate-200 rounded-lg p-4 ${
        className || ""
      }`}
    >
      {children}
    </div>
  );
};

export default Card;
