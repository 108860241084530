import React, { FC, useState } from "react";

interface PhoneNumberInputProps {
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  placeholder: string;
  loadingAfterSubmission?: boolean; // New optional prop
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  phoneNumber,
  setPhoneNumber,
  placeholder,
  loadingAfterSubmission = false, // Default value is false
}) => {
  const formatPhoneNumber = (phoneNumber: string) => {
    // Remove all non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, "");

    // Check if the number starts with '1' and has at least 4 digits

    if (cleaned.startsWith("1") && cleaned.length >= 4) {
      const parts = [
        "+1",
        "-(",
        cleaned.slice(1, 4),
        ")",
        cleaned.length > 4 ? "-" + cleaned.slice(4, 7) : "",
        cleaned.length > 7 ? "-" + cleaned.slice(7, 11) : "",
      ];
      console.log("parts", parts);
      return parts.join("");
    }
    // If it doesn't start with '1' or has less than 4 digits
    else {
      const parts = [
        "+1",
        "-(",
        cleaned.slice(1, 3),
        cleaned.length > 3 ? ")" : "",
        cleaned.length > 3 ? "-" + cleaned.slice(3, 6) : "",
        cleaned.length > 6 ? "-" + cleaned.slice(6, 10) : "",
      ];
      return parts.join("");
    }
  };
  const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    // value is the value with only numbers and '+'
    console.log("in", value);
    value = value.replace(/[^\d+]/g, "");
    console.log("out", value);
    if (!value.startsWith("+1")) {
      if (value.length == 1) {
        if (value == "+") {
          value = "+";
        } else {
          if (value != "1") {
            value = "+1" + value;
          } else {
            value = "+1";
          }
        }
      } else if (value.length == 2) {
        if (value == "+1") {
          value = "+1";
        } else {
          value = "+1" + value;
        }
      } else if (value.length == 0) {
        value = "";
      } else if (value.startsWith("1")) {
        value = "+" + value;
      } else {
        value = "+1" + value;
      }
    }
    console.log("pre format", value);
    // format value if value new length is longer than old length
    if (value.length > phoneNumber.replace(/[^\d+]/g, "").length) {
      value = formatPhoneNumber(value);
    }
    console.log("post format", value);
    setPhoneNumber(value);
  };

  return (
    <input
      type="tel"
      value={phoneNumber}
      onChange={onPhoneNumberChange}
      placeholder={placeholder}
      className="bg-white border-none border-slate-400 rounded-full text-lg md:w-80 w-26  text-slate-900 py-3 px-4 focus:border-slate-700 focus:border-none outline-none"
      autoComplete="tel"
      disabled={loadingAfterSubmission} // Disable input when loading
    />
  );
};

export default PhoneNumberInput;
