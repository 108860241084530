import React from "react";
import Menu from "./menu";
import Expandable from "../../components/UICore/Expandable";
import Marquee from "react-fast-marquee";
import Card from "../../components/UICore/Card";
import Constants from "../../constants";
import {
  TbCircleNumber1Filled,
  TbCircleNumber2Filled,
  TbCircleNumber3Filled,
} from "react-icons/tb";

import { FaInstagram } from "react-icons/fa";

import { MdEmail, MdPhone } from "react-icons/md";
// import InputWithButton from "../../components/InputWithButton";
import PhoneNumberInput from "../../components/UICore/PhoneNumberInput";
import {
  useFeatureFlagEnabled,
  PostHogFeature,
  usePostHog,
  useFeatureFlagVariantKey,
} from "posthog-js/react";

import { useState } from "react";
import useForms from "../../hooks/useForms";
const numberToIcon = (number: number) => {
  switch (number) {
    case 1:
      return <TbCircleNumber1Filled size={34} />;
    case 2:
      return <TbCircleNumber2Filled size={34} />;
    case 3:
      return <TbCircleNumber3Filled size={34} />;
    default:
      return <TbCircleNumber1Filled size={34} />;
  }
};

interface StepItem {
  title: string;
  child: React.ReactNode;
}
interface FAQItem {
  title: string;
  text: string;
}

const Landing: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const {
    submitPhoneNumberLoading,
    submitPhoneNumber,
    isPhoneNumberSubmitted,
  } = useForms();
  const posthog = usePostHog();
  const postHogUserIdentifier = usePostHog().get_distinct_id();

  // posthog.featureFlags.override("home-button-test", "test");
  const landingCtaFlagValue = useFeatureFlagVariantKey("landing-cta");
  const handleNavigation = (
    e: React.MouseEvent<HTMLAnchorElement>,
    id: string
  ): void => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      window.scrollTo({ top: section.offsetTop - 100, behavior: "smooth" });
    }
  };

  const stepsData: StepItem[] = [
    {
      title: "Submit Application",
      child: (
        <p className="text-lg text-slate-700">
          Fill out a quick{" "}
          <a
            href={`${Constants.APPLICATION_LINK}#id_${postHogUserIdentifier}`}
            className="underline "
            target="blank_"
          >
            application
          </a>{" "}
          to tell us about yourself.
        </p>
      ),
    },
    {
      title: "Tell Alice What You're Looking For",
      child: (
        <p className="text-lg text-slate-700">
          Talk with Alice. She is an AI that you can call and text, just like a
          real person. She'll ask about you, what you're looking for, and of
          course about your dates. Be as specific and particular as you'd like.
        </p>
      ),
    },
    {
      title: "Meet Your Match",
      child: (
        <p className="text-lg text-slate-700">
          Alice will find 2-3 matches per month for you focusing on quality over
          quantity.
        </p>
      ),
    },
  ];

  const faqData: FAQItem[] = [
    {
      title: "Is this a dating app?",
      text: "Well, no. This is not an app. Alice is a virtual match maker you can call and text like a real human. If you know what you're looking for you can tell her.",
    },
    {
      title: "Who is Alice?",
      text: "Alice is an AI matchmaking agent you can talk with like a real human.",
    },
    {
      title: "Where is this available?",
      text: "NYC, SF, LA. More cities coming soon.",
    },
    {
      title: "How much does it cost?",
      text: "$200 a month. Automatic refund if you don't like Alice's matches.",
    },
  ];

  const callToAction = () => {
    if (landingCtaFlagValue === "get-phone-number") {
      return (
        <div className="flex flex-row">
          {isPhoneNumberSubmitted ? (
            <p className="text-lg text-emerald-700">
              Check your phone for a text from Alice. She'll reach out with an
              application.
            </p>
          ) : (
            <div className="space-x-2 bg-white p-2 rounded-full border-none border-slate-400">
              <PhoneNumberInput
                phoneNumber={phoneNumber}
                setPhoneNumber={(value) => setPhoneNumber(value)}
                placeholder="+1 (_ _ _) - _ _ _ _ - _ _ _"
                loadingAfterSubmission={submitPhoneNumberLoading}
              />
              <button
                className="bg-[#c49f2a] hover:bg-[#bd9e36] text-white font-semibold rounded-full py-3 px-4 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() => {
                  submitPhoneNumber(phoneNumber);
                }}
                disabled={submitPhoneNumberLoading}
              >
                Apply Now
              </button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <a href={Constants.APPLICATION_LINK} target="_blank">
          <button className="bg-[#c49f2a] hover:bg-[#bd9e36] text-white font-semibold rounded-full py-3 px-4 font-lato">
            Get Started
          </button>
        </a>
      );
    }
  };

  return (
    <div className="bg-[#f4f1ec] flex flex-col items-center max-w-screen overflow-hidden">
      <div className="flex flex-col items-end w-full p-6 space-y-4  h-8 ">
        <a
          href="https://www.instagram.com/alice_the_matchmaker/"
          target="_blank"
          className="hidden md:flex"
        >
          <FaInstagram size={28} className="cursor-pointer" />
        </a>
      </div>

      <Menu />
      <section className="flex flex-col items-center w-full px-6 md:w-9/12 space-y-4 mt-0 bg-red-50"></section>
      <section className="min-h-screen justify-center flex flex-col ">
        <div className="flex flex-col items-center my-4 space-y-4 mt-0 md:mt-20 ">
          <div className="flex flex-col md:flex-row items-center justify-between space-x-8">
            <div className="space-y-4  items-center justify-center flex flex-col text-center md:text-left md:items-start">
              <h1 className="font-playfair text-5xl">
                Meet <b>Alice</b>
                <br />
                Your virtual matchmaker
              </h1>
              <p className="text-2xl font-lato">Never swipe again.</p>
              {/* <p className="text-2xl font-lato">Ditch that same opening line.</p> */}

              {callToAction()}

              {/* <a href={Constants.APPLICATION_LINK} target="_blank">
                <button className="bg-[#c49f2a] hover:bg-[#bd9e36] text-white font-semibold rounded-full py-3 px-4 my-4">
                  Apply Now
                </button>
              </a> */}
            </div>
            <img
              src={require("../../assets/media/park bench.png")}
              alt="park bench"
              className="w-[400px] hidden md:flex"
            />
          </div>
          <div className="w-screen px-6 md:w-8/12 md:px-0 overflow-hidden">
            <p className="font-lato text-xl pb-4 text-gray-500 ">
              "Digital Cupid: The AI Matchmaker Taking Gen Z by Storm" -{" "}
              <span className="underline pointer">Insider</span>
            </p>
            <p className="font-lato text-xl hidden md:block">
              With <b>extraordinary</b> vetted members from...
            </p>
            <p className="font-lato text-xl md:hidden">
              <b>Extraordinary</b> vetted members from
            </p>
            <Marquee
              gradient
              gradientColor="#f4f1ec"
              speed={55}
              style={{ width: "100%" }}
            >
              {[
                "l-s-bw.png",
                "lbe.png",
                "l-g-bw.png",
                "l-h-bw.png",
                "logos-mck.png",
                "l-b.png",
                "l-ms-bw.png",
                "logos-gs-bw.png",
              ].map((logo) => (
                <img
                  src={require(`../../assets/company_graphics/${logo}`)}
                  alt="logo"
                  className="w-[160px] md:w-[200px] px-8"
                  key={logo}
                />
              ))}
            </Marquee>
          </div>

          <a
            href="#about"
            className="font-lato text-slate-500 mt-12 underline cursor-pointer text-lg hidden md:flex"
            onClick={(e) => handleNavigation(e, "about")}
          >
            How it works
          </a>
        </div>
      </section>
      <section className="flex flex-col items-center w-full px-6 md:w-9/12 space-y-4 mb-12 ">
        <h2
          id="hype"
          className="font-playfair text-3xl font-semibold text-center"
        >
          Know What You're Looking For?
        </h2>
        <p className="text-2xl font-lato">Just Ask.</p>
        <div className="flex flex-col md:flex-row md:space-x-8 items-center justify-center w-full space-y-4 md:space-y-0 ">
          <Card className="flex flex-col space-x-2 items-left justify-center w-10/12 min-w-[300px] max-w-[350px] space-y-4 px-8 ">
            <p className="text-lg h-[140px]">
              <b className="text-xl">"</b>I want someone that always has a
              positive attitude, and will come with me to farmer's market Sunday
              mornings.
              <b className="text-xl">"</b>
            </p>
            <div className="flex flex-row space-x-2 items-center justify-left">
              <img
                src={require("../../assets/headshots/h1.png")}
                alt="alyssa"
                className="rounded-full w-10 h-10 border-2 border-ivory-800"
              />
              <p className="italic">Alyssa, SF</p>
            </div>
          </Card>

          <Card className="flex flex-col space-x-2 items-left justify-center w-10/12 min-w-[300px] max-w-[350px] space-y-4 px-8 ">
            <p className="text-lg h-[140px]">
              <b className="text-xl">"</b>Looking for a partner that brings the
              sense of adventure out of me. Maybe we can travel the world...
              <b className="text-xl">"</b>
            </p>
            <div className="flex flex-row space-x-2 items-center justify-left">
              <img
                src={require("../../assets/headshots/h3.png")}
                alt="alyssa"
                className="rounded-full w-10 h-10 border-2 border-ivory-800"
              />
              <p className="italic">Sam, LA</p>
            </div>
          </Card>
          <Card className="flex flex-col space-x-2 items-left justify-center w-10/12 min-w-[300px] max-w-[350px] space-y-4 px-8 ">
            <p className="text-lg h-[140px] ">
              <b className="text-xl">"</b>I like cozying up with under a blanket
              and reading. Maybe we can bond over Spanish Literature.
              <b className="text-xl">"</b>
            </p>
            <div className="flex flex-row space-x-2 items-center justify-left">
              <img
                src={require("../../assets/headshots/h2.png")}
                alt="alyssa"
                className="rounded-full w-10 h-10 border-2 border-ivory-800 shadow-lg"
              />
              <p className="italic">Claire, NYC</p>
            </div>
          </Card>
        </div>
      </section>
      <section className="flex flex-col items-center w-full px-6 md:w-9/12 space-y-4  ">
        <div className="flex justify-between items-center w-full">
          <div className="w-full md:p-12 space-y-4">
            <h2 id="about" className="font-playfair text-3xl font-semibold">
              How It Works
            </h2>
            {stepsData.map((item, index) => (
              <Card key={index} className="w-full space-y-2">
                <div className="flex items-center space-x-2 py-1">
                  <span>{numberToIcon(index + 1)}</span>
                  <h3 className="font-playfair text-2xl">{item.title}</h3>
                </div>
                {item.child}
              </Card>
            ))}
          </div>
          <img
            src={require("../../assets/media/woman-on-phone.png")}
            alt="phone"
            className="w-4/12 hidden md:flex p-12"
          />
        </div>
      </section>
      <section className="flex flex-col items-center w-9/12 space-y-4 justify-center py-12">
        <h1 className="font-playfair text-3xl font-semibold">
          Ready to meet your match?
        </h1>

        {callToAction()}
      </section>
      <section className="flex flex-row items-center justify-between w-full md:w-10/12 max-w-[1220px]">
        <img
          src={require("../../assets/media/roses-sketch.png")}
          alt="phone"
          className="w-3/12 hidden md:flex"
        />
        <div className="md:w-9/12 w-full  md:p-12 space-y-4 items-center justify-center flex flex-col">
          <h2 id="faq" className="font-playfair text-3xl font-semibold">
            FAQ
          </h2>
          <div className="flex flex-col w-full space-y-3 items-center">
            {faqData.map((item, index) => (
              <Expandable
                key={index}
                previewText={item.title}
                expandedContent={item.text}
              />
            ))}
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center w-8/12 space-y-5 justify-center font-lato mt-12 md:mt-0">
        <h2 id="support" className="font-playfair text-3xl font-semibold">
          Support
        </h2>
        <p className="text-lg text-center">Our team is here to help you 24/7</p>
        <Card className="flex flex-row space-x-2 items-center justify-center w-[240px]">
          <MdEmail size={32} />
          <p className="text-lg font-lato">support@alice.com</p>
        </Card>

        <Card className="flex flex-row space-x-2 items-center justify-center w-[240px]">
          <MdPhone size={32} />
          <p className="text-lg font-lato">+1 (408) 341 9215</p>
        </Card>
      </section>

      <footer className="flex flex-col space-y-1 h-[40px] mt-12 py-8 border-t w-full items-center justify-center">
        <div className="flex flex-row space-x-2 items-center justify-center">
          <a href="/" className="text-sm font-lato text-slate-500 underline">
            Privacy Policy
          </a>
          <a href="/" className="text-sm font-lato text-slate-500 underline">
            Terms of Service
          </a>
        </div>
        <p className="text-sm font-lato text-slate-500">DDL Labs 2024</p>
      </footer>
    </div>
  );
};

export default Landing;
