import exp from "constants";
import constants from "../constants";

const addPhoneNumber = async (phoneNumber: string, postHogUserId: string) => {
  try {
    phoneNumber = phoneNumber.replace(/[^0-9+]/g, "");
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/signup/submitPhoneNumber`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber, postHogUserId }),
      }
    );
    if (response.ok) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error submitting phone number", error);
    return false;
  }
};

export default addPhoneNumber;
