import React, { FC, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import profiles from "./sample_profiles";
import { Profile } from "../../core/models";
import Card from "../../components/UICore/Card";
const ProfilePage: FC = () => {
  const [profileId, setProfileId] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [profile, setProfile] = useState<Profile | null>(null);
  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      setProfileId(id);
      setProfile(profiles[id]);
    }
  }, [searchParams]);

  return (
    <div className="bg-[#f4f1ec] flex flex-col items-center max-w-screen flex-grow max-h-screen overflow-hidden md:h-screen">
      {/* <div className="flex flex-col h-screen items-center"> */}
      <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4 ">
        <a href="/" className="text-2xl font-bold font-playfair text-gray-400">
          Alice
        </a>
        <a
          href="/profile?id=Md3mVl05V4"
          className="text-lg font-lato text-gray-400 underline"
        >
          support
        </a>
      </div>

      {profile ? (
        <div className="flex flex-col items-center w-10/12 max-w-[400px]">
          <img
            src={profile.profile_image}
            alt={profile.name}
            className="rounded-full h-52 w-52 m-4 border-4  border-ivory-700 shadow-sm  mt-2"
          />
          <h1 className="text-4xl font-bold font-playfair ">{profile.name}</h1>

          <p className="font-lato italic">{profile.location}</p>
          <Card className="my-4 space-y-4">
            <p>{profile.bio}</p>
            <p>Interests include {profile.interest}</p>
            <p>
              From {profile.hometown} | Age {profile.age}
            </p>
          </Card>
        </div>
      ) : (
        <p>Profile not found</p>
      )}
      <footer className="mt-auto text-center text-xs text-gray-400 space-y-1 py-3  border-t">
        <p>DDL Labs 2024</p>
        <p>Need help? support@alicemate.com</p>
      </footer>
      {/* </div> */}
    </div>
  );
};

export default ProfilePage;
